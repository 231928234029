import { useEffect } from 'react'

const PrivyWidget = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.type = 'text/javascript'
    script.innerHTML = `
      var _d_site = _d_site || 'FEE10384504580EB1521314E';
      (function(p, r, i, v, y) {
       p[i] = p[i] || function() { (p[i].q = p[i].q || []).push(arguments) };
       v = r.createElement('script'); v.async = 1; v.src = 'https://widget.privy.com/assets/widget.js';
       y = r.getElementsByTagName('script')[0]; y.parentNode.insertBefore(v, y);
      })(window, document, 'Privy');
    `
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default PrivyWidget
