import PrivyWidget from './PrivyWidget'

export const Head = () => (
  <>
    <meta
      name="viewport"
      content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
    />
    <PrivyWidget />
  </>
)
